import React from 'react';

import {
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Theme,
  MenuItem
} from '@material-ui/core';

import {
  Done,
  Clear
} from '@material-ui/icons';

import '../styles.css';
import { makeStyles } from '@material-ui/styles';

import { functions, firestore } from '../../firebase';
import firebase from 'firebase';

enum ProcessState {
  Idle,
  Loading,
  Success,
  Fail
}

interface IData {
  state: number
  turn: number
  time: {nanoseconds: number, seconds: number}[]
}

const useStyles = makeStyles((theme: Theme) => ({

  textField: {
    color: '#FFFFFF'
  },
  cssButton: {
    color: 'rgb(122, 117, 107)',
    backgroundColor: 'rgb(255, 222, 180)',
    '&:hover': {
      backgroundColor: 'rgb(250, 208, 131)',
    },
  },
  cssLabel: {
    '&$cssFocused': {
      color: 'rgb(255, 222, 180)',
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:before': {
      borderBottomColor: 'rgb(255, 222, 180)',
    },
    '&:hover': {
      borderBottomColor: 'rgb(255, 222, 180)',
    },
    '&:after': {
      borderBottomColor: 'rgb(255, 222, 180)',
    },
  },
}));

const CommandGame: React.FC = () => {

  const classes = useStyles();

  const [processState, setProcessState] = React.useState(ProcessState.Idle);
  const [data, setData] = React.useState<IData>({
    turn: 0,
    state: 0,
    time: [],
  });

  React.useEffect(() => {
    firestore.collection('game').doc('control').onSnapshot((snapshot: firebase.firestore.DocumentSnapshot) => {
      if (snapshot.exists){
        const d = snapshot.data();
        if (d !== undefined){
          setData({...data, turn: d.turn, state: d.state, time: d.time});
        }
      }
    });
  });

  const gameState = (n: number) => {
    switch(n){
      case 0:
        return 'Ready to start!!!!!';
      case 1:
        return 'Playing ^_^';
      case 2:
        return 'Ended';
      default:
        return '黑人問號?'
    }
  }

  const onStartGameClicked = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    //
    console.log('Start Game button is clicked');
    setProcessState(ProcessState.Loading);
    try {

      const response = await functions.httpsCallable('startGame')();
      console.log(`%o`, response);
      setProcessState(ProcessState.Idle);
    }
    catch(e){

      console.error(`Encounter error: %o`, e);
      console.log(e.status);
      setProcessState(ProcessState.Idle);
    }
  }

  const onResetGameClicked = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    //
    console.log('Start Game button is clicked');
    setProcessState(ProcessState.Loading);
    try {

      const response = await functions.httpsCallable('resetGame')();
      console.log(`%o`, response);
      setProcessState(ProcessState.Idle);
    }
    catch(e){

      console.error(`Encounter error: %o`, e);
      console.log(e.status);
      setProcessState(ProcessState.Idle);
    }
  }

  const onNextTurnClicked = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    //
    console.log('Next Turn button is clicked');
    setProcessState(ProcessState.Loading);

    const currentTime = firebase.firestore.Timestamp.fromDate(new Date());
    try {

      const response = await functions.httpsCallable('nextTurn')({time: currentTime});
      console.log(`%o`, response);
      setProcessState(ProcessState.Idle);
    }
    catch(e){

      console.error(`Encounter error: %o`, e);
      console.log(e.status);
      setProcessState(ProcessState.Idle);
    }
  }

  const onEndGameClicked = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    //
    console.log('End Game button is clicked');
    setProcessState(ProcessState.Loading);
    try {

      const response = await functions.httpsCallable('endGame')();
      console.log(`%o`, response);
      setProcessState(ProcessState.Idle);
    }
    catch(e){

      console.error(`Encounter error: %o`, e);
      console.log(e.status);
      setProcessState(ProcessState.Idle);
    }
  }

  const renderStartGameButton = () => {
    if (data.state === 0){
      return (
        <Grid item xs={2}>
          <Button 
            className={classes.cssButton}
            onClick={onStartGameClicked}
            disabled={processState === ProcessState.Loading}
          >
            Start Game
          </Button>
        </Grid>
      )
    }
    else{
      return <div />
    }
  }

  const renderNextTurnButton = () => {
    if (data.state === 1){
      return (
        <Grid item xs={2}>
          <Button 
            className={classes.cssButton}
            onClick={onNextTurnClicked}
            disabled={processState === ProcessState.Loading}
          >
            Next Turn
          </Button>
        </Grid>
      )
    }
    else{
      return <div />
    }
  }

  const renderResetGameButton = () => {
    if (data.state === 1 || data.state === 2){
      return (
        <Grid item xs={2}>
          <Button 
            className={classes.cssButton}
            onClick={onResetGameClicked}
            disabled={processState === ProcessState.Loading}
          >
            Reset Game
          </Button>
        </Grid>
      )
    }
    else{
      return <div />
    }
  }

  const renderEndGameButton = () => {
    if (data.state === 1){
      return (
        <Grid item xs={2}>
          <Button 
            className={classes.cssButton}
            onClick={onEndGameClicked}
            disabled={processState === ProcessState.Loading}
          >
            End Game
          </Button>
        </Grid>
      )
    }
    else{
      return <div />
    }
  }

  const renderProcessState = () => {
    
    if (processState === ProcessState.Idle) return <div />
    else if (processState === ProcessState.Success) return <Done className='colorGreen'/>
    else if (processState === ProcessState.Fail) return <Clear className='colorRed'/>
    else return <CircularProgress />
  }

  const renderListTitle = () => {
    return (
      <Grid container>
        <Grid item xs={4}><Typography align='left'>Turn</Typography></Grid>
        <Grid item xs={4}><Typography align='left'>Time</Typography></Grid>
        <Grid item xs={4}><Typography align='left'>Duration</Typography></Grid>
      </Grid>
    );
  }

  const renderListRow = (d: Date, index: number) => {

    let duration = '----';
    if (index > 0){
      const { seconds, nanoseconds } = data.time[index-1];
      const prevDate = new firebase.firestore.Timestamp(seconds, nanoseconds).toDate();
      const diff = new Date(d.getTime() - prevDate.getTime());
      duration = `${diff.getMinutes()}:${diff.getSeconds()}`;
    }

    return (
      <Grid container alignItems='flex-start' key={index}>
        <Grid item xs={4}><Typography align='left'>{index}</Typography></Grid>
        <Grid item xs={4}><Typography align='left'>{`${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`}</Typography></Grid>
        <Grid item xs={4}><Typography align='left'>{duration}</Typography></Grid>
      </Grid>
    )
  }

  const renderTimeList = () => {
    if (data.time.length === 0){
      return (
        <Grid item>
          <Typography>
            ...
          </Typography>
        </Grid>
      )
    }

    return (
      <Grid container item>
        { renderListTitle() }
        { data.time.map((d: {nanoseconds: number, seconds: number}, index:number) => {
            const converted = new firebase.firestore.Timestamp(d.seconds, d.nanoseconds).toDate();
            return renderListRow(converted, index)
          })
        }
      </Grid>
    )
  }


  return (
    <div className='dashboardComponent'>
      <Grid container direction='column' alignItems='flex-start' spacing={2}>
        
        <Grid container item justify='space-between'>
          <Grid item><Typography>Game Control</Typography></Grid>
          <Grid item>{ renderProcessState() }</Grid>
        </Grid>

        <Grid container item spacing={2}>
          { renderStartGameButton() }
          { renderNextTurnButton() }
          { renderEndGameButton() }
          { renderResetGameButton() }
        </Grid>


        <Grid item><Typography>Game State: { gameState(data.state) }</Typography></Grid>

        <Grid item><Typography>Current Turn: { data.turn }</Typography></Grid>

        { renderTimeList() }

      </Grid>
    </div>
  );
}

export default CommandGame;
