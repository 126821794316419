import React from 'react';

import {
  Grid
} from '@material-ui/core';

import './App.css';
import CommandRegistration from './components/commands/registration';
import CommandGame from './components/commands/game';
import PanelUsers from './components/panels/users';
import PanelGroups from './components/panels/groups';
import PanelCards from './components/panels/cards';

const App: React.FC = () => {

  return (
    <div className="App">
      <header className="App-header">

        <Grid container direction='row'>

          <Grid container item direction='row' spacing={2}>

            <Grid container item xs direction='column' spacing={2}>
              <Grid item><CommandGame /></Grid>
              <Grid item><PanelGroups /></Grid>
              <Grid item><CommandRegistration /></Grid>
              <Grid item><PanelUsers /></Grid>
            </Grid>

            <Grid container item xs direction='column' spacing={2}>
            
              <Grid item><PanelCards title='Group 1' doc='group1'/></Grid>
              <Grid item><PanelCards title='Group 2' doc='group2'/></Grid>
              <Grid item><PanelCards title='Group 3' doc='group3'/></Grid>
              <Grid item><PanelCards title='Group 4' doc='group4'/></Grid>
            </Grid>

          </Grid>

        </Grid>
        
      </header>
    </div>
  );
}

export default App;
