import firebase from 'firebase';

const app = firebase.initializeApp({
  apiKey: "AIzaSyApUuSM1i0k1YU-JE0vKMH9oocKbOglMiE",
  authDomain: "hong-kong-start-game.firebaseapp.com",
  databaseURL: "https://hong-kong-start-game.firebaseio.com",
  projectId: "hong-kong-start-game",
  storageBucket: "hong-kong-start-game.appspot.com",
  messagingSenderId: "549950126729",
  appId: "1:549950126729:web:5551cf4e362bd01c"
})


const firestore = app.firestore();
const auth = app.auth();

const functions = app.functions();

if (process.env.NODE_ENV === 'development'){
  console.log(`[Cloud Functions] Using function emulotor at 'http://localhost:5000'`);
  functions.useFunctionsEmulator('http://localhost:5000');
}

export { auth, firestore, functions };