import React from 'react';

import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  Theme

} from '@material-ui/core';

import '../styles.css';
import { makeStyles } from '@material-ui/styles';

import { firestore, functions } from '../../firebase';

enum ProcessState {
  Idle,
  Loading,
}

const useStyles = makeStyles((theme: Theme) => ({

  textField: {
    color: '#FFFFFF'
  },
  cssButton: {
    color: 'rgb(122, 117, 107)',
    backgroundColor: 'rgb(255, 222, 180)',
    '&:hover': {
      backgroundColor: 'rgb(250, 208, 131)',
    },
  },
  cssLabel: {
    '&$cssFocused': {
      color: 'rgb(255, 222, 180)',
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:before': {
      borderBottomColor: 'rgb(255, 222, 180)',
    },
    '&:hover': {
      borderBottomColor: 'rgb(255, 222, 180)',
    },
    '&:after': {
      borderBottomColor: 'rgb(255, 222, 180)',
    },
  },
}));

const PanelGroups: React.FC = () => {

  const classes = useStyles();

  const [processState, setProcessState] = React.useState<ProcessState>(ProcessState.Idle)
  const [group1, setGroup1] = React.useState<firebase.firestore.DocumentData>();
  const [group2, setGroup2] = React.useState<firebase.firestore.DocumentData>();
  const [group3, setGroup3] = React.useState<firebase.firestore.DocumentData>();
  const [group4, setGroup4] = React.useState<firebase.firestore.DocumentData>();

  React.useEffect(() => {

    firestore.collection('game').doc('group1').onSnapshot((snapshot: firebase.firestore.DocumentSnapshot) => {
      // console.log('%o', snapshot.docs);
      setGroup1(snapshot.data())
    })

    firestore.collection('game').doc('group2').onSnapshot((snapshot: firebase.firestore.DocumentSnapshot) => {
      // console.log('%o', snapshot.docs);
      setGroup2(snapshot.data())
    })

    firestore.collection('game').doc('group3').onSnapshot((snapshot: firebase.firestore.DocumentSnapshot) => {
      // console.log('%o', snapshot.docs);
      setGroup3(snapshot.data())
    })

    firestore.collection('game').doc('group4').onSnapshot((snapshot: firebase.firestore.DocumentSnapshot) => {
      // console.log('%o', snapshot.docs);
      setGroup4(snapshot.data())
    })

    }, []);


  const renderProcessState = () => {
    
    if (processState === ProcessState.Idle) return <div />
    else return <CircularProgress />
  }

  const renderListTitle = () => {
    return (
      <Grid container>
        <Grid item xs={2}><Typography align='left'>Group</Typography></Grid>
        <Grid item xs={4}><Typography align='left'>Location</Typography></Grid>
        <Grid item xs={3}><Typography align='left'>Score</Typography></Grid>
        <Grid item xs={3}><Typography align='left'>Cards</Typography></Grid>
      </Grid>
    );
  }

  const renderListRow = () => {
    return (
      <Grid container>

        <Grid container alignItems='flex-start'>
          <Grid item xs={2}><Typography align='left'>1</Typography></Grid>
          <Grid item xs={4}><Typography align='left'>{group1 !== undefined ? group1.location : ''}</Typography></Grid>
          <Grid item xs={3}><Typography align='left'>{group1 !== undefined ? group1.score : ''}</Typography></Grid>
          <Grid item xs={3}><Typography align='left'>0</Typography></Grid>
        </Grid>

        <Grid container alignItems='flex-start'>
          <Grid item xs={2}><Typography align='left'>2</Typography></Grid>
          <Grid item xs={4}><Typography align='left'>{group2 !== undefined ? group2.location : ''}</Typography></Grid>
          <Grid item xs={3}><Typography align='left'>{group2 !== undefined ? group2.score : ''}</Typography></Grid>
          <Grid item xs={3}><Typography align='left'>0</Typography></Grid>
        </Grid>

        <Grid container alignItems='flex-start'>
          <Grid item xs={2}><Typography align='left'>3</Typography></Grid>
          <Grid item xs={4}><Typography align='left'>{group3 !== undefined ? group3.location : ''}</Typography></Grid>
          <Grid item xs={3}><Typography align='left'>{group3 !== undefined ? group3.score : ''}</Typography></Grid>
          <Grid item xs={3}><Typography align='left'>0</Typography></Grid>
        </Grid>

        <Grid container alignItems='flex-start'>
          <Grid item xs={2}><Typography align='left'>4</Typography></Grid>
          <Grid item xs={4}><Typography align='left'>{group4 !== undefined ? group4.location : ''}</Typography></Grid>
          <Grid item xs={3}><Typography align='left'>{group4 !== undefined ? group4.score : ''}</Typography></Grid>
          <Grid item xs={3}><Typography align='left'>0</Typography></Grid>
        </Grid>

      </Grid>
    );
  }

  const renderList = () => {

    return (
      <Grid container item>
        { renderListTitle() }
        { renderListRow() }
      </Grid>
    )
  }

  return (
    <div className='dashboardComponent'>

      <Grid container direction='column' alignItems='flex-start' spacing={2}>

        <Grid container item justify='space-between'>
          <Grid item><Typography>Group Panel</Typography></Grid>
          <Grid item>{ renderProcessState() }</Grid>
        </Grid>

        { renderList() }
      </Grid>
    </div>
  );
}


export default PanelGroups;