import React from 'react';

import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  Theme

} from '@material-ui/core';

import '../styles.css';
import { makeStyles } from '@material-ui/styles';

import { firestore, functions } from '../../firebase';

enum ProcessState {
  Idle,
  Loading,
}

const useStyles = makeStyles((theme: Theme) => ({

  textField: {
    color: '#FFFFFF'
  },
  cssButton: {
    color: 'rgb(122, 117, 107)',
    backgroundColor: 'rgb(255, 222, 180)',
    '&:hover': {
      backgroundColor: 'rgb(250, 208, 131)',
    },
  },
  cssLabel: {
    '&$cssFocused': {
      color: 'rgb(255, 222, 180)',
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:before': {
      borderBottomColor: 'rgb(255, 222, 180)',
    },
    '&:hover': {
      borderBottomColor: 'rgb(255, 222, 180)',
    },
    '&:after': {
      borderBottomColor: 'rgb(255, 222, 180)',
    },
  },
}));

const PanelUsers: React.FC = () => {

  const classes = useStyles();

  const [processState, setProcessState] = React.useState<ProcessState>(ProcessState.Idle)
  const [data, setData] = React.useState<firebase.firestore.QueryDocumentSnapshot[]>([]);

  React.useEffect(() => {

    firestore.collection('users').onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
      // console.log('%o', snapshot.docs);
      if (snapshot.docChanges().length > 0){
        // Only update when there is any change
        setData(snapshot.docs);
      }
    })
  }, []);

  const roleName = (n: number) => {
    switch(n){
      case 1:
        return 'OC';
      case 2:
        return 'Leader';
      case 3:
        return 'Member';
      default:
        return '黑人問號?'
    }
  }

  const onRemoveClick = (uid: string) => async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

    console.log('Remove button is clicked');
    setProcessState(ProcessState.Loading);
    try {
      console.log(`Removing ${uid}...`);
      const response = await functions.httpsCallable('removeUser')({uid: uid});
      console.log(`%o`, response);
      setProcessState(ProcessState.Idle);
    }
    catch (e){

      console.error(`Encounter error: %o`, e);
      setProcessState(ProcessState.Idle);
    }
  }

  const renderProcessState = () => {
    
    if (processState === ProcessState.Idle) return <div />
    else return <CircularProgress />
  }

  const renderListTitle = () => {
    return (
      <Grid container>
        <Grid item xs={4}><Typography align='left'>Email</Typography></Grid>
        <Grid item xs={3}><Typography align='left'>Password</Typography></Grid>
        <Grid item xs={1}><Typography align='left'>Group</Typography></Grid>
        <Grid item xs={2}><Typography align='left'>Role</Typography></Grid>
        <Grid item xs={2}><Typography align='left'></Typography></Grid>
      </Grid>
    );
  }

  const renderListRow = (d: firebase.firestore.DocumentData) => {
    return (
      <Grid container alignItems='flex-start' key={d.uid}>
        <Grid item xs={4}><Typography align='left'>{d.email}</Typography></Grid>
        <Grid item xs={3}><Typography align='left'>{d.password}</Typography></Grid>
        <Grid item xs={1}><Typography align='left'>{d.group}</Typography></Grid>
        <Grid item xs={2}><Typography align='left'>{roleName(d.role)}</Typography></Grid>
        <Grid item xs={2}>
          <Button 
            className={classes.cssButton}
            onClick={onRemoveClick(d.uid)}
            disabled={processState === ProcessState.Loading}
          >
            Remove
          </Button>
        </Grid>
      </Grid>
    );
  }

  const renderList = () => {

    if (data.length === 0){
      return (
        <Grid item>
          <Typography>
            No user record
          </Typography>
        </Grid>
      )
    }

    return (
      <Grid container item>
        { renderListTitle() }
        { data.map((d: firebase.firestore.QueryDocumentSnapshot) => 
            renderListRow(d.data()))
        }
      </Grid>
    )
  }

  return (
    <div className='dashboardComponent'>

      <Grid container direction='column' alignItems='flex-start' spacing={2}>

        <Grid container item justify='space-between'>
          <Grid item><Typography>User Panel</Typography></Grid>
          <Grid item>{ renderProcessState() }</Grid>
        </Grid>

        { renderList() }
      </Grid>
    </div>
  );
}


export default PanelUsers;