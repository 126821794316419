import React from 'react';

import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  Theme

} from '@material-ui/core';

import '../styles.css';

import { firestore } from '../../firebase';

interface ICardPanelProps {
  title: string;
  doc: string;
}

const PanelCards: React.FC<ICardPanelProps> = (props) => {

  const [cards, setCards] = React.useState<firebase.firestore.QueryDocumentSnapshot[]>([]);

  React.useEffect(() => {

    firestore.collection('game').doc(props.doc).collection('cards').onSnapshot((snapshot: firebase.firestore.QuerySnapshot) => {
      // console.log('%o', snapshot.docs);
      setCards(snapshot.docs)
    })

    }, []);


  const renderListTitle = () => {
    return (
      <Grid container>
        <Grid item xs={4}><Typography align='left'>ID</Typography></Grid>
        <Grid item xs={4}><Typography align='left'>Code</Typography></Grid>
        <Grid item xs={4}><Typography align='left'>Status</Typography></Grid>
      </Grid>
    );
  }
  
  const renderListRow = (c: firebase.firestore.QueryDocumentSnapshot) => {

    const data = c.data();
    const id = data === undefined ? 'NO DATA' : data.id;
    const code = data === undefined ? 'NO DATA' : data.code;
    const status = 'NO STATUS'

    return (
      <Grid container>

        <Grid container alignItems='flex-start'>
          <Grid item xs={4}><Typography align='left'>{ id }</Typography></Grid>
          <Grid item xs={4}><Typography align='left'>{ code }</Typography></Grid>
          <Grid item xs={4}><Typography align='left'>{ status }</Typography></Grid>
        </Grid>

      </Grid>
    );
  }

  const renderList = () => {

    if (cards.length === 0){
      return (
        <Grid item>
          <Typography>No Card</Typography>
        </Grid>
      )
    }

    return (
      <Grid container item>
        { renderListTitle() }
        { cards.map((c: firebase.firestore.QueryDocumentSnapshot) => renderListRow(c)) }
      </Grid>
    )
  }

  return (
    <div className='dashboardComponent'>

      <Grid container direction='column' alignItems='flex-start' spacing={2}>

        <Grid container item justify='space-between'>
          <Grid item><Typography>Card Panel - {props.title}</Typography></Grid>
        </Grid>

        { renderList() }
      </Grid>
    </div>
  );
}


export default PanelCards;