import React from 'react';

import {
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Theme,
  MenuItem
} from '@material-ui/core';

import {
  Done,
  Clear
} from '@material-ui/icons';

import '../styles.css';
import { makeStyles } from '@material-ui/styles';

import { functions } from '../../firebase';


enum ProcessState {
  Idle,
  Loading,
  Success,
  Fail
}

const groups = [
  {
    value: 1,
    label: '1'
  },
  {
    value: 2,
    label: '2'
  },
  {
    value: 3,
    label: '3'
  },
  {
    value: 4,
    label: '4'
  },
]

const roles = [
  {
    value: 1,
    label: 'OC'
  },
  {
    value: 2,
    label: 'Leader'
  },
  {
    value: 3,
    label: 'Member'
  }
]

const useStyles = makeStyles((theme: Theme) => ({

  textField: {
    color: '#FFFFFF'
  },
  cssButton: {
    color: 'rgb(122, 117, 107)',
    backgroundColor: 'rgb(255, 222, 180)',
    '&:hover': {
      backgroundColor: 'rgb(250, 208, 131)',
    },
  },
  cssLabel: {
    '&$cssFocused': {
      color: 'rgb(255, 222, 180)',
    },
  },
  cssFocused: {},
  cssUnderline: {
    '&:before': {
      borderBottomColor: 'rgb(255, 222, 180)',
    },
    '&:hover': {
      borderBottomColor: 'rgb(255, 222, 180)',
    },
    '&:after': {
      borderBottomColor: 'rgb(255, 222, 180)',
    },
  },
}));

const CommandRegistration: React.FC = () => {

  const classes = useStyles();

  const [values, setValues] = React.useState({
    email: '',
    password: '',
    group: 1,
    state: ProcessState.Idle,
    message: '',
    role: 1,
  })

  const handleChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [key]: event.target.value });
  }

  const onRegClick = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

    console.log('Reg button is clicked');
    setValues({...values, state: ProcessState.Loading, message: ''});

    try {

      const { email, password, group, role } = values;

      console.log(`Creating user with [Email]: ${email} [Password]: ${password}`)

      // Create New User
      // const user = await auth.createUserWithEmailAndPassword(email, password);
      // console.log('User: %o', user)
      // const uid = user.user ? user.user.uid : ''
      // // Initialize new user document
      // await firestore.collection('users').add({
      //   email: email,
      //   password: password,
      //   uid: uid,
      //   role: role,
      //   group: group,
      // })
      const response = await functions.httpsCallable('registerUser')({email, password, role, group});
      console.log(`%o`, response);

      setValues({...values, state: ProcessState.Success, message: 'Success', email: '', password: ''});
    }
    catch (e){

      console.error(`Encounter error: %o`, e);
      console.log(e.status);
      setValues({...values, state: ProcessState.Fail, message: e.message.toString()})
    }

    // functions.
  }

  const renderProcessState = () => {
    
    if (values.state === ProcessState.Idle) return <div />
    else if (values.state === ProcessState.Success) return <Done className='colorGreen'/>
    else if (values.state === ProcessState.Fail) return <Clear className='colorRed'/>
    else return <CircularProgress />
  }

  return (
    <div className='dashboardComponent'>
      <Grid container direction='column' alignItems='flex-start' spacing={2}>
        <Grid item>
          <Typography>Create New Account</Typography>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={3}>
            <TextField
              className={classes.textField}
              classes={{root: classes.textField}}
              InputLabelProps={{
                className: classes.textField,
                classes:{
                  root: classes.cssLabel, 
                  focused: classes.cssFocused
                }
              }}
              InputProps={{
                className: classes.textField,
                classes:{
                  underline: classes.cssUnderline
                }
              }}
              label='Email'
              value={values.email}
              onChange={handleChange('email')}
              fullWidth
            />
          </Grid>

          <Grid item xs={2}>
            <TextField 
              className={classes.textField}
              classes={{root: classes.textField}}
              InputLabelProps={{
                className: classes.textField,
                classes:{
                  root: classes.cssLabel, 
                  focused: classes.cssFocused
                }
              }}
              InputProps={{
                className: classes.textField,
                classes:{
                  underline: classes.cssUnderline
                }
              }}
              label='Password'
              value={values.password}
              onChange={handleChange('password')}
              fullWidth
            />
          </Grid>

          <Grid item xs={2}>
            <TextField
              className={classes.textField}
              classes={{root: classes.textField}}
              InputLabelProps={{
                className: classes.textField,
                classes:{
                  root: classes.cssLabel, 
                  focused: classes.cssFocused
                }
              }}
              InputProps={{
                className: classes.textField,
                classes:{
                  underline: classes.cssUnderline
                }
              }}
              select
              label="Group"
              value={values.group}
              onChange={handleChange('group')}
            >
              {groups.map((option: {value: number, label: String}) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={2}>
            <TextField
              className={classes.textField}
              classes={{root: classes.textField}}
              InputLabelProps={{
                className: classes.textField,
                classes:{
                  root: classes.cssLabel, 
                  focused: classes.cssFocused
                }
              }}
              InputProps={{
                className: classes.textField,
                classes:{
                  underline: classes.cssUnderline
                }
              }}
              select
              label="Role"
              value={values.role}
              onChange={handleChange('role')}
            >
              {roles.map((option: {value: number, label: String}) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          
          <Grid item xs={2}>
            <Button 
              className={classes.cssButton}
              onClick={onRegClick}
              fullWidth
              disabled={values.state === ProcessState.Loading}
            >
              Submit
            </Button>
          </Grid>

          <Grid item xs={1}>
            { renderProcessState() }
          </Grid>

        </Grid>
      
        <Grid item>
          <Typography>{values.message}</Typography>
        </Grid>
      
      </Grid>
    </div>
  );
}

export default CommandRegistration;
